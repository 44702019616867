import React from 'react';
import '../css/homestyle.css';

function Header() {
  return (
    <>
      <div className='headerContainer'>
        <h1 className="header">SuperUseful!</h1>
      </div>
    </>
  );
}

export default Header;
